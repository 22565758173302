@import '~src/scss/index.scss';
.container {
  min-height: calc(100vh - 72rem - 64rem);
}

.img {
  position: relative;
  max-height: 254rem;
}

.ff {
  max-height: 254rem;
}

.o {
  position: absolute;
  top: 0;
  left: 245rem;
  max-height: 254rem;
}

@include mobile {

  .img {
    position: relative;
    max-width: 100%;
    overflow: hidden;
  }

  .ff {
    max-width: 100%;
  }

  .o {
    top: 0;
    left: calc(50% - 40rem);
    height: 100%;
  }
}
